import {computed, onBeforeMount, type Ref, ref, watch} from 'vue';
import {type LocationQueryValue, useRouter} from 'vue-router';

export type SortedStates = 'asc' | 'desc' | null;

// eslint-disable-next-line no-unused-vars
export type SortFn<T> = (item1: T, item2: T, sort: SortedStates) => 1 | 0 | -1;

export interface Sorting {
	key: string,
	value: SortedStates,
}

/**
 * This will sort strings case-insensitive.
 * @param string1 string 1
 * @param string2 string 2
 * @param sorting asc or desc
 */
export const sortCaseInsensitive = (string1: string, string2: string, sorting: SortedStates) => {
	if (sorting === null) {
		return 0;
	}

	const string1UpperCase = string1.toUpperCase();
	const string2UpperCase = string2.toUpperCase();

	if (sorting === 'asc') {
		if (string1UpperCase > string2UpperCase) {
			return 1;
		}

		if (string1UpperCase < string2UpperCase) {
			return -1;
		}
	}

	if (sorting === 'desc') {
		if (string1UpperCase > string2UpperCase) {
			return -1;
		}

		if (string1UpperCase < string2UpperCase) {
			return 1;
		}
	}

	return 0;
};

export const defaultSortingFn = <T>(item1: T, item2: T, sorting: SortedStates) => {
	if (sorting === null) {
		return 0;
	}

	if (typeof item1 === 'string' && typeof item2 === 'string') {
		return sortCaseInsensitive(item1, item2, sorting);
	}

	if (sorting === 'asc') {
		return item1 > item2 ? 1 : -1;
	}

	if (sorting === 'desc') {
		return item1 < item2 ? 1 : -1;
	}

	return 0;
};

export const useSorting = <T extends Record<string, unknown>>(
	items: Ref<Array<T>>,
	defaultSorting: Sorting,
	allowedSortingKeys: Array<keyof T>,
	customSortingFunctions: Record<string, SortFn<T>> = {}
) => {
	const router = useRouter();
	const sorting = ref<Sorting>(defaultSorting);

	const sortedItems = computed(() => {
		return items.value.toSorted((item1, item2) => {
			const key = sorting.value.key;
			const sort = sorting.value.value;

			const customSorting = typeof customSortingFunctions[key] === 'function';
			if (customSorting) {
				return customSortingFunctions[key](item1, item2, sort);
			}

			const item1Value = item1[key];
			const item2Value = item2[key];
			return defaultSortingFn(item1Value, item2Value, sort);
		});
	});

	const updateSorting = (key: string) => {
		sorting.value = {
			key,
			value: sorting.value.value === 'asc' ? 'desc' : 'asc'
		};
	};

	const getQueryWithoutSort = () => {
		const query = router.currentRoute.value.query;
		const newQuery: Record<string, LocationQueryValue | LocationQueryValue[]> = {};

		for (const [key, value] of Object.entries(query)) {
			if (!key.startsWith('sort[')) {
				newQuery[key] = value;
			}
		}

		return newQuery;
	};

	watch(sorting, (sortingValue) => {
		if (sortingValue.value === null) {
			router.replace({
				query: {
					...getQueryWithoutSort()
				}
			});
			return;
		}

		router.replace({
			query: {
				...getQueryWithoutSort(),
				[`sort[${String(sortingValue.key)}]`]: sortingValue.value
			}
		});
	});

	onBeforeMount(() => {
		const query = router.currentRoute.value.query;

		for (const [key, value] of Object.entries(query)) {
			if (key.startsWith('sort[')) {
				const id = String(key.replace('sort[', '').replace(']', ''));

				if (!allowedSortingKeys.includes(id)) {
					// Key is not a sortable value
					continue;
				}

				if (value !== 'asc' && value !== 'desc') {
					continue;
				}

				sorting.value = {
					key: id,
					value
				};

				break;
			}
		}
	});

	return {
		sortedItems,
		sorting,
		updateSorting
	};

};
